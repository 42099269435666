import type {} from '@redux-devtools/extension'; // required for devtools typing
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

type DepositMode = 'channel' | 'contract';

interface SettingsStore {
  boostEnabled: boolean;
  _depositMode: DepositMode;
  fillOrKillEnabled: boolean;
  slippageTolerancePercent: number | 'auto';
  swapDeadlineMinutes: number;
  toggleBoostEnabled: () => void;
  setBoostEnabled: (boostEnabled: boolean) => void;
  // toggleFillOrKillEnabled: () => void;
  setSlippageTolerancePercent: (slippageTolerancePercent: number | 'auto') => void;
  setSwapDeadlineMinutes: (swapDeadlineMinutes: number) => void;
  // setDepositMode: (depositMode: DepositMode) => void;
}

const initialData = {
  boostEnabled: true,
  _depositMode: 'channel',
  fillOrKillEnabled: true,
  slippageTolerancePercent: 'auto',
  swapDeadlineMinutes: 5,
} as const;

const useSettingsStore = create<SettingsStore>()(
  devtools(
    persist(
      immer((set) => ({
        ...initialData,
        toggleBoostEnabled: () =>
          set((state) => ({
            boostEnabled: !state.boostEnabled,
          })),
        setBoostEnabled: (boostEnabled) => set({ boostEnabled }),
        // toggleFillOrKillEnabled: () =>
        //   set((state) => ({ fillOrKillEnabled: !state.fillOrKillEnabled })),
        setSlippageTolerancePercent: (slippageTolerancePercent) =>
          set({ slippageTolerancePercent }),
        setSwapDeadlineMinutes: (swapDeadlineMinutes) => set({ swapDeadlineMinutes }),
        // setDepositMode: (depositMode) => set({ _depositMode: depositMode }),
      })),
      {
        name: 'cf.swap-settings',
        version: 6,
        migrate: (persistedState, version) => {
          const state = persistedState as SettingsStore;

          if (version < 5) {
            state.fillOrKillEnabled = true;
            state.slippageTolerancePercent = 'auto';
          }

          if (version < 6 && state.swapDeadlineMinutes === 15) {
            state.swapDeadlineMinutes = 5;
          }

          return state;
        },
      },
    ),
  ),
);

export const selectDepositMode = (state: SettingsStore) => {
  if (state.fillOrKillEnabled) return 'channel';
  return state._depositMode; // eslint-disable-line no-underscore-dangle
};

export default useSettingsStore;
