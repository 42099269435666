import { type SwapStatusResponseV2 } from '@chainflip/sdk/swap';
import { differenceInTimeAgo } from '@chainflip/utils/date';
import { abbreviate } from '@chainflip/utils/string';
import { type ChainflipToken } from '@/shared/assets/tokens';
import ExpandableContainer from '@/shared/components/ExpandableContainer';
import {
  CheckCircleIcon,
  CloseCircleIcon,
  HourglassIcon,
  SlantedArrowIcon,
} from '@/shared/icons/small';
import { TokenAmount } from '@/shared/utils';
import { buildChainflipExplorerLink } from '@/shared/utils/chainflip';
import { type StatusResponse } from '../../integrations';
import { isDepositRejected } from '../../utils/sdk';

type EventLog = {
  message: string | JSX.Element;
  status: 'success' | 'processing' | 'error';
  link?: string;
  timestamp?: number;
};

const icons: Record<EventLog['status'], JSX.Element> = {
  success: <CheckCircleIcon className="text-cf-green-1" />,
  processing: <HourglassIcon className="text-cf-orange-2" />,
  error: <CloseCircleIcon className="text-cf-red-1" />,
};

export const ExternalLinkButton = ({ href }: { href: string }) => (
  <a
    href={href}
    target="_blank"
    rel="noreferrer"
    className="flex h-6 w-6 items-center justify-center rounded-md border border-cf-gray-5 bg-cf-gray-4 transition ease-out hover:bg-cf-gray-5 hover:text-cf-white"
  >
    <SlantedArrowIcon />
  </a>
);

const Log = ({ status, message, link, timestamp }: EventLog) => (
  <div className="flex h-9 items-center justify-between gap-x-2 text-cf-light-3">
    <div className="flex items-center gap-x-1">
      <div>{icons[status]}</div>
      <div>{message}</div>
    </div>
    <div className="flex items-center gap-x-2.5 whitespace-nowrap">
      {timestamp != null && (
        <span className="text-cf-light-2">
          {differenceInTimeAgo(new Date(timestamp).toISOString())}
        </span>
      )}
      {link && <ExternalLinkButton href={link} />}
    </div>
  </div>
);

const AmountAndSymbol = ({ amount, token }: { amount: string; token: ChainflipToken }) => (
  <span className="text-cf-white">
    {TokenAmount.fromAsset(amount, token.chainflipId).toCapped()} {token.symbol}
  </span>
);

type GetEgressFields<T> = T extends { swapEgress: infer U } ? U : never;
type EgressFields = NonNullable<GetEgressFields<SwapStatusResponseV2>>;

const buildEgressLog = (
  egress: EgressFields,
  egressToken: ChainflipToken,
  address: string,
): EventLog => {
  if (egress.failedAt) {
    return {
      message: (
        <>
          Sending <AmountAndSymbol amount={egress.amount} token={egressToken} /> failed
        </>
      ),
      status: 'error',
      link: buildChainflipExplorerLink(egress.failedBlockIndex),
      timestamp: egress.failedAt,
    };
  }

  if (egress.witnessedAt) {
    return {
      message: (
        <>
          Sent <AmountAndSymbol amount={egress.amount} token={egressToken} /> to{' '}
          <span className="text-cf-white">{abbreviate(address)}</span>
        </>
      ),
      status: 'success',
      link: buildChainflipExplorerLink(egress.witnessedBlockIndex),
      timestamp: egress.witnessedAt,
    };
  }

  return {
    message: (
      <>
        Sending <AmountAndSymbol amount={egress.amount} token={egressToken} /> to{' '}
        <span className="text-cf-white">{abbreviate(address)}</span>
      </>
    ),
    status: 'processing',
    link: undefined,
    timestamp: undefined,
  };
};

export const buildEventLog = (
  status: SwapStatusResponseV2,
  srcToken: ChainflipToken,
  destToken: ChainflipToken,
) => {
  const events: EventLog[] = [];

  const swapEgress = 'swapEgress' in status && status.swapEgress ? status.swapEgress : undefined;
  const refundEgress =
    'refundEgress' in status && status.refundEgress ? status.refundEgress : undefined;

  if (swapEgress) {
    events.push(buildEgressLog(swapEgress, destToken, status.destAddress));
  }
  if (refundEgress) {
    events.push(
      buildEgressLog(
        refundEgress,
        srcToken,
        status.depositChannel?.fillOrKillParams?.refundAddress ?? '',
      ),
    );
  }
  if (refundEgress && !swapEgress && status.state !== 'FAILED') {
    events.push({
      message: 'Slippage tolerance exceeded',
      status: 'error',
      link: buildChainflipExplorerLink(refundEgress.scheduledBlockIndex),
      timestamp: refundEgress.scheduledAt,
    });
  }

  const swap = 'swap' in status && status.swap ? status.swap : undefined;
  const dca = swap?.dca;
  const regular = swap?.regular;

  if (swap && dca) {
    if (!('swapEgress' in status) && !('refundEgress' in status)) {
      events.push({
        message: (
          <>
            Swapping <span className="text-cf-white">{srcToken.symbol}</span> for{' '}
            <span className="text-cf-white">{destToken.symbol}</span> ({dca.executedChunks}/
            {status.depositChannel?.dcaParams?.numberOfChunks ?? 0} chunks)
          </>
        ),
        status: 'processing',
        link: buildChainflipExplorerLink(dca.lastExecutedChunk?.executedBlockIndex),
        timestamp: dca.lastExecutedChunk?.executedAt,
      });
    } else if ('swapEgress' in status) {
      events.push({
        message: (
          <>
            Swapped <AmountAndSymbol amount={swap.swappedInputAmount} token={srcToken} /> for{' '}
            <AmountAndSymbol amount={swap.swappedOutputAmount} token={destToken} />
          </>
        ),
        status: 'success',
        link: buildChainflipExplorerLink(dca.lastExecutedChunk?.executedBlockIndex),
        timestamp: dca.lastExecutedChunk?.executedAt,
      });
    }
  } else if (swap && regular) {
    if (regular.executedAt) {
      events.push({
        message: (
          <>
            Swapped <AmountAndSymbol amount={swap.originalInputAmount} token={srcToken} /> for{' '}
            <AmountAndSymbol amount={swap.swappedOutputAmount ?? '0'} token={destToken} />
          </>
        ),
        status: 'success',
        link: buildChainflipExplorerLink(regular.executedBlockIndex),
        timestamp: regular.executedAt,
      });
    }

    if (regular.scheduledAt) {
      events.push({
        message: 'Swap scheduled',
        status: 'success',
        link: buildChainflipExplorerLink(regular.scheduledBlockIndex),
        timestamp: regular.scheduledAt,
      });
    }
  }

  if ('deposit' in status) {
    const amountAndSymbol = <AmountAndSymbol amount={status.deposit.amount} token={srcToken} />;
    if (status.state === 'RECEIVING') {
      events.push({
        message: <>Receiving {amountAndSymbol}</>,
        status: 'processing',
        link: undefined,
        timestamp: undefined,
      });
    } else {
      if (status.deposit.failedAt) {
        events.push({
          message: isDepositRejected({ integration: 'chainflip', integrationData: status })
            ? 'Deposit rejected by broker'
            : 'Deposit failed',
          status: 'error',
          link: buildChainflipExplorerLink(status.deposit.failedBlockIndex),
          timestamp: status.deposit.failedAt,
        });
      } else if (status.deposit.witnessedBlockIndex && !status.boost?.boostedAt) {
        events.push({
          message: <>{amountAndSymbol} deposited</>,
          status: 'success',
          link: buildChainflipExplorerLink(status.deposit.witnessedBlockIndex),
          timestamp: status.deposit.witnessedAt,
        });
      }

      if (status.boost) {
        if (status.boost.boostedAt) {
          events.push({
            message: <>{amountAndSymbol} deposit boosted after 1 block confirmation</>,
            status: 'success',
            link: buildChainflipExplorerLink(status.boost.boostedBlockIndex),
            timestamp: status.boost.boostedAt,
          });
        } else if (status.boost.skippedAt) {
          events.push({
            message: 'Boost attempt failed. Not enough available liquidity',
            status: 'error',
            link: buildChainflipExplorerLink(status.boost.skippedBlockIndex),
            timestamp: status.boost.skippedAt,
          });
        }
      }
    }
  }

  if (status.state === 'WAITING') {
    events.push({
      message: 'Waiting for deposit',
      status: 'processing',
      link: undefined,
      timestamp: undefined,
    });
  }

  if (status.depositChannel) {
    events.push({
      message: 'Deposit channel created',
      status: 'success',
      link: buildChainflipExplorerLink(status.depositChannel.id, 'channels'),
      timestamp: status.depositChannel.createdAt,
    });
  }

  return events;
};

export const EventLog = ({ status }: { status: StatusResponse }) => {
  if (status.integration !== 'chainflip' || !status.integrationData) return null;

  const { route, integrationData } = status;

  const events = buildEventLog(integrationData, route.srcToken, route.destToken);

  return (
    <ExpandableContainer
      label="Log"
      count={events.length}
      className="shadow-grayPop1"
      buttonClassName="data-open:pb-1.5"
    >
      {events.map((ev, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Log {...ev} key={i} />
      ))}
    </ExpandableContainer>
  );
};
