import {
  type RouteResponse as SquidRoute,
  type StatusResponse as SquidStatus,
} from '@0xsquid/sdk/dist/types';
import type {
  QuoteResponse as ChainflipQuoteResponse,
  SwapStatusResponseV2 as ChainflipSwapStatusResponse,
} from '@chainflip/sdk/swap';
import { type ChainId } from '@/shared/assets/chains';
import { type ChainflipToken, type Token } from '@/shared/assets/tokens';
import { type TokenAmount } from '@/shared/utils';
import { ChainflipIntegration } from './chainflip';
import { IntegrationManager } from './manager';
import { SquidIntegration } from './squid';

type TransactionHash = string;

const integrations = ['chainflip', 'squid'] as const;
export type Integration = (typeof integrations)[number];

export const isSupportedIntegration = (
  integration: string | string[] | undefined,
): integration is Integration => integrations.includes(integration as Integration);

const swapStates = [
  'waiting_for_src_tx',
  'waiting_for_src_tx_confirmation',
  'waiting_for_dest_tx',
  'completed',
  'failed',
  'unknown', // integration api or rpc is not reachable
] as const;
export type SwapStatus = (typeof swapStates)[number];

export interface RouteRequest {
  srcChainId: ChainId;
  destChainId: ChainId;
  srcTokenAddress: string;
  destTokenAddress: string;
  amount: bigint;
}

export interface RouteResponseStep<T extends Token = Token> {
  protocolName: string;
  protocolLink: string | undefined;
  srcToken: T;
  srcAmount: TokenAmount;
  destToken: T;
  destAmount: TokenAmount;
}

interface BaseRouteResponse<I extends Integration, Data, T extends Token = Token> {
  id: string;
  integration: I;
  integrationData: Data;
  srcToken: T;
  srcAmount: TokenAmount;
  destToken: T;
  destAmount: TokenAmount;
  steps: RouteResponseStep<T>[];
  gasFees: { token: T; amount: TokenAmount }[];
  platformFees: { name: string; token: T; amount: TokenAmount }[];
  durationSeconds: number;
}
export type IntegrationData = {
  isBoosted: boolean;
  hasBoostQuote: boolean;
  defaultDurationSeconds: number;
  dcaParams?: {
    numberOfChunks: number;
    chunkIntervalBlocks: number;
  };
  quote?: ChainflipQuoteResponse['quote'];
};
export type ChainflipRouteResponse = BaseRouteResponse<
  'chainflip',
  IntegrationData,
  ChainflipToken
>;
export type SquidRouteResponse = BaseRouteResponse<'squid', SquidRoute['route']>;

export type RouteResponse = ChainflipRouteResponse | SquidRouteResponse;

interface BaseStatusResponse<I extends Integration, Data, R extends RouteResponse = RouteResponse> {
  id: string;
  shareableId: string | undefined;
  integration: I;
  integrationData: Data;
  route: R;
  status: SwapStatus;
  depositAddress: string | undefined;
  srcTransactionHash: string | null | undefined;
  srcConfirmationCount: number | null | undefined;
  destAddress: string | undefined;
  destTransactionHash: string | undefined;
  duration: number | undefined;
}

export type ChainflipStatusResponse = BaseStatusResponse<
  'chainflip',
  ChainflipSwapStatusResponse | undefined,
  ChainflipRouteResponse
>;

export type SquidStatusResponse = BaseStatusResponse<'squid', SquidStatus | undefined>;

export type StatusResponse = ChainflipStatusResponse | SquidStatusResponse;

export type PrepareSwapResponse = {
  integration: Integration;
  id: string;
};

export type ExecuteSwapResponse = {
  integration: Integration;
  integrationData: TransactionHash | undefined;
  error: string | undefined;
};

export const integrationManager = IntegrationManager.from({
  chainflip: () => new ChainflipIntegration(),
  squid: () => SquidIntegration.init(),
});
